import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import Home from './Home/Home'; // Ensure this import is correct
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const isFirstVisit = sessionStorage.getItem('isFirstVisit');

// Suppress the ResizeObserver loop limit exceeded warning
window.addEventListener('error', (e) => {
  if (e.message === "ResizeObserver loop limit exceeded") {
    e.stopImmediatePropagation();
  }
});

if (!isFirstVisit) {
  sessionStorage.setItem('isFirstVisit', 'false');
  
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  setTimeout(() => {
    root.render(
      <React.StrictMode>
        <Home />
      </React.StrictMode>
    );
  }, 5000);

} else {
  console.log('This is not the first visit. App will not be rendered.');
  root.render(
    <React.StrictMode>
      <Home/>
    </React.StrictMode>
  );
}

reportWebVitals();
