import { database } from './firebaseconfig';
import { ref, get } from 'firebase/database';

export const fetchData = async (tableName) => {
  try {
    const dbRef = ref(database, tableName);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.error('No data available');
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
