import React, { useState, useEffect } from 'react';
import { fetchData } from '../Firebase/realtimedatabase';
import { fetchImageFromStorage } from '../Firebase/storage';
import './Home.css';
import './nav-components.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import batteryIcon from '../Assets/battery.png';
import displayIcon from '../Assets/display.png';
import processorIcon from '../Assets/processor.png';
import ramIcon from '../Assets/ram.png';
import storageIcon from '../Assets/storage.png';
import osIcon from '../Assets/os.png';
import gpuIcon from '../Assets/gpu.png';
import backIcon from '../Assets/back.png';
import closeIcon from '../Assets/close.png';
import ownerpic from '../Assets/Square_logo.jpeg';
import phoneIcon from '../Assets/phone.png'; 
import whatsappIcon from '../Assets/whatsapp.png';
import instagramIcon from '../Assets/instagram.png';
import facebookIcon from '../Assets/facebook.png';
import gmailIcon from '../Assets/gmail.png';

function Home() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('home');
  const [games, setGames] = useState([]);
  const [laptops, setLaptops] = useState([]);
  const [components, setComponents] = useState([]); 
  const [selectedLaptop, setSelectedLaptop] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null); 
  const [selectedGame, setSelectedGame] = useState(null); 
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showChatOverlay, setShowChatOverlay] = useState(false); 
  const [showReviews, setShowReviews] = useState(false); 

  // Scroll to top when the component detail section is displayed
  useEffect(() => {
    if (currentSection === 'componentDetail') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll to top
      });
    }
  }, [currentSection]);

  useEffect(() => {
    if (currentSection === 'games') {
      loadGames();
    } else if (currentSection === 'refurbished') {
      loadLaptops();
    } else if (currentSection === 'components') {
      loadComponents(); 
    }
  }, [currentSection]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReviews(true);
      const script = document.createElement('script');
      script.src = "https://widget.taggbox.com/embed-lite.min.js";
      script.async = true;
      document.body.appendChild(script);
    }, 1000); 
    return () => clearTimeout(timer);
  }, []);

  const loadComponents = async () => {
    try {
      const componentsData = await fetchData('components');
      if (componentsData) {
        const componentsList = await Promise.all(
          Object.keys(componentsData).map(async key => {
            const component = componentsData[key];
            const imageUrl = await fetchImageFromStorage('components', component.Cover_image);
            return {
              ...component,
              key: key,
              imageUrl: imageUrl
            };
          })
        );
        setComponents(componentsList);
      } else {
        setComponents([]);
      }
    } catch (error) {
      console.error('Error loading components:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadGames = async () => {
    try {
      const gamesData = await fetchData('Games');
      if (gamesData) {
        const gamesList = await Promise.all(
          Object.keys(gamesData).map(async key => {
            const game = gamesData[key];
            const imageUrl = await fetchImageFromStorage('Games', game.Image);
            return {
              ...game,
              key: key,
              imageUrl: imageUrl
            };
          })
        );
        setGames(gamesList);
      } else {
        setGames([]);
      }
    } catch (error) {
      console.error('Error loading games:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadLaptops = async () => {
    try {
      const laptopsData = await fetchData('Refurbished');
      if (laptopsData) {
        const laptopsList = await Promise.all(
          Object.keys(laptopsData).map(async key => {
            const laptop = laptopsData[key];
            const imageUrl = await fetchImageFromStorage('Refurbished', laptop.Cover_image);
            return {
              ...laptop,
              key: key,
              imageUrl: imageUrl
            };
          })
        );
        setLaptops(laptopsList);
      } else {
        setLaptops([]);
      }
    } catch (error) {
      console.error('Error loading laptops:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleComponentClick = async (component) => {
    setSelectedComponent(component);
    const imageNames = component.Images?.split(',') || [];
    const imageUrls = await Promise.all(
      imageNames.map(image => fetchImageFromStorage('components', image.trim()))
    );
    setCarouselImages(imageUrls);
    setCurrentImageIndex(0);
    setCurrentSection('componentDetail');
  };

  const handleLaptopClick = async (laptop) => {
    setSelectedLaptop(laptop);

    const imageNames = laptop.Images.split(',');
    const imageUrls = await Promise.all(
      imageNames.map(image => fetchImageFromStorage('Refurbished', image.trim()))
    );

    setCarouselImages(imageUrls);
    setCurrentImageIndex(0);
    setCurrentSection('laptopDetail');
  };

  const handleGameClick = (game) => {
    setSelectedGame(game); 
    setCurrentSection('gameDetail');
  };

  const handleNavClick = (section) => {
    if (section === 'home') {
      window.location.reload(); 
    } else {
      setCurrentSection(section);
      setIsNavOpen(false);
    }
  };

  const goBackToComponents = () => {
    setCurrentSection('components');
  };

  const goBackToRefurbished = () => {
    setCurrentSection('refurbished');
  };

  const handleChatNow = () => {
    setShowChatOverlay(true);
  };

  const redirectToWhatsApp = () => {
    let productName = '';

    if (selectedComponent) {
      productName = selectedComponent.Name;
    } else if (selectedLaptop) {
      productName = selectedLaptop.Name;
    }

    const phoneNumber = '9164915365';
    const message = `I am interested in ${productName} which was listed on your website`;
    const whatsappUrl = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const closeChatOverlay = () => {
    setShowChatOverlay(false);
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <div className="home-logo" onClick={() => handleNavClick('home')}>
          <h1>SQUARE</h1>
          <h2>COMPUTERS</h2>
        </div>
        <div className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
          ☰
        </div>
        <nav className="home-nav">
          <ul>
            <li onClick={() => handleNavClick('refurbished')}>REFURBISHED</li>
            <li onClick={() => handleNavClick('components')}>PC COMPONENTS</li>
            <li onClick={() => handleNavClick('about')}>ABOUT</li>
          </ul>
        </nav>
      </header>

      <div className={`mobile-nav ${isNavOpen ? 'active' : ''}`}>
        <span className="close-btn" onClick={() => setIsNavOpen(false)}>&times;</span>
        <ul>
          <li onClick={() => handleNavClick('refurbished')}>REFURBISHED</li>
          <li onClick={() => handleNavClick('components')}>PC COMPONENTS</li>
          <li onClick={() => handleNavClick('about')}>ABOUT</li>
        </ul>
      </div>

      <main className="home-main">
        {currentSection === 'home' && (
          <>
            <h1 className="welcome-text">WELCOME!</h1>
            <h2 className="subtext first-text">A best place to buy</h2>
            <h2 className="subtext second-text">High quality Refurbished Laptops</h2>
            {showReviews && (
              <div className="taggbox-widget-container">
                <div className="taggbox" data-widget-id="162558" data-tags="false"></div>
              </div>
            )}
          </>
        )}

        {/* Refurbished Section */}
        {currentSection === 'refurbished' && (
          <section className="refurbished-section">
            <div className="refurbished-container">
              {loading ? (
                <p>Loading refurbished laptops...</p>
              ) : laptops.length > 0 ? (
                laptops.map((laptop, index) => (
                  <div key={index} className="laptop-card" onClick={() => handleLaptopClick(laptop)}>
                    <img src={laptop.imageUrl} alt={laptop.Name} />
                    <h3>{laptop.Name}</h3>
                    <p>Price: ₹{laptop.Price}</p>
                  </div>
                ))
              ) : (
                <p>No refurbished laptops available.</p>
              )}
            </div>
          </section>
        )}

        {/* Component Section */}
        {currentSection === 'components' && (
          <section className="components-section">
            <div className="components-container">
              {loading ? (
                <p>Loading PC components...</p>
              ) : components.length > 0 ? (
                components.map((component, index) => (
                  <div key={index} className="component-card" onClick={() => handleComponentClick(component)}>
                    <img src={component.imageUrl} alt={component.Name} />
                    <h3>{component.Name}</h3>
                    <p>Price: ₹{component.Price}</p>
                  </div>
                ))
              ) : (
                <p>No PC components available.</p>
              )}
            </div>
          </section>
        )}

        {/* For Components with Image Gallery */}
        {currentSection === 'componentDetail' && selectedComponent && (
          <section className="component-detail-section">
            <button className="back-button" onClick={goBackToComponents}>
              <img src={backIcon} alt="Back" />
            </button>
            <ImageGallery
              items={carouselImages.map(image => ({
                original: image,
                thumbnail: image
              }))}
              showFullscreenButton={true}
              showPlayButton={false}
              onSlide={setCurrentImageIndex}
              startIndex={currentImageIndex}
            />
            <div className="component-details">
              <h3>{selectedComponent.Name}</h3>
              <p className="component-price">Price: ₹{selectedComponent.Price}</p>

              <ul className="component-details-list">
                {Object.keys(selectedComponent).map((key) =>
                  key !== 'Category' && key !== 'Cover_image' && key !== 'Price' && key !== 'Name' && key !== 'key' && key !== 'imageUrl' && key !== 'Images' ? (
                    <li key={key}>
                      <strong>{key}</strong>: {selectedComponent[key]}
                    </li>
                  ) : null
                )}
              </ul>

              <button className="book-now-button" onClick={handleChatNow}>Book Now</button>
            </div>
          </section>
        )}

        {/* Laptop Detail Section with Image Gallery */}
        {currentSection === 'laptopDetail' && selectedLaptop && (
          <section className="laptop-detail-section">
            <button className="back-button" onClick={goBackToRefurbished}>
              <img src={backIcon} alt="Back" />
            </button>

            <div className="image-gallery-container">
              <ImageGallery 
                items={carouselImages.map((imageUrl) => ({
                  original: imageUrl,
                  thumbnail: imageUrl,
                }))}
                showThumbnails={true}
                showFullscreenButton={true}
                showPlayButton={false} 
              />
            </div>

            <div className="laptop-details">
              <h3>{selectedLaptop.Name}</h3>
              <h2>Price: ₹{selectedLaptop.Price}</h2>
              <p><img src={processorIcon} alt="Processor Icon" className="icon" /> {selectedLaptop.Processor}</p>
              <p><img src={ramIcon} alt="RAM Icon" className="icon" /> {selectedLaptop.Ram}</p>
              <p><img src={storageIcon} alt="Storage Icon" className="icon" /> {selectedLaptop.Storage}</p>
              <p><img src={displayIcon} alt="Display Icon" className="icon" /> {selectedLaptop.Display}</p>
              <p><img src={batteryIcon} alt="Battery Icon" className="icon" /> {selectedLaptop.Battery}</p>
              <p><img src={osIcon} alt="OS Icon" className="icon" /> {selectedLaptop.OS}</p>
              <p><img src={gpuIcon} alt="GPU Icon" className="icon" /> {selectedLaptop.GPU}</p>
            </div>

            <button className="book-now-button" onClick={handleChatNow}>Book Now</button>
          </section>
        )}

        {showChatOverlay && (
          <div className="chat-now-overlay">
            <div className="chat-now-card">
              <button className="close-button" onClick={closeChatOverlay}>
                <img src={closeIcon} alt="Close" />
              </button>
              <h2>Confirm that you are interested in this item</h2>
              <p>When you click on the "Chat Now" button below, you will be redirected to WhatsApp and chat with the owner of this business.</p>
              <button className="chat-now-button" onClick={redirectToWhatsApp}>Chat Now</button>
            </div>
          </div>
        )}

        {currentSection === 'about' && (
          <section className="about-section">
            <p className="about-text-main">Computer Sales and Services</p>
            <p className="about-text-sub">Since 2014</p>
            <img src={ownerpic} alt="Owner" className="owner-image" />
            <p className="owner-name">Sharath Kumar</p>
            <div className="contact-icons-row">
              <a href="tel:+919164915365" role="button">
                <img src={phoneIcon} alt="Phone" className="contact-icon" />
              </a>
              <a href="https://www.instagram.com/square_saligrama365?utm_source=qr&igsh=MTR3M25rZ2k1YTZ5Nw==" target="_blank" rel="noopener noreferrer" role="button">
                <img src={instagramIcon} alt="Instagram" className="social-icon" />
              </a>
              <a href="https://wa.me/919164915365" target="_blank" rel="noopener noreferrer" role="button">
                <img src={whatsappIcon} alt="WhatsApp" className="contact-icon" />
              </a>
            </div>
            <div className="social-media-icons">
              <a href="mailto:squaresaligrama@gmail.com" role="button">
                <img src={gmailIcon} alt="Gmail" className="contact-icon" />
              </a>
              <a href="https://www.facebook.com/share/XiPRkJ8HygFNjRuj/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" role="button">
                <img src={facebookIcon} alt="Facebook" className="social-icon" />
              </a>
            </div>
            <div className="maps-location">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3879.6225773637284!2d74.70745177368148!3d13.49733890313683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc97355b4827b9%3A0xa700ec0ecc1fb0df!2sSquare%20Computers!5e0!3m2!1sen!2sin!4v1725772531802!5m2!1sen!2sin"
                width="400"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Square Computers Location"
              ></iframe>
            </div>
            <p className="copyright-text">© Square Computers, All rights reserved</p>
          </section>
        )}
      </main>
    </div>
  );
}

export default Home;
