import { storage } from './firebaseconfig';
import { ref, getDownloadURL } from "firebase/storage";

// Function to fetch image from Firebase Storage
export const fetchImageFromStorage = async (folderPath, imageName) => {
    try {
        // Use the folderPath passed as a parameter to build the storage reference
        const storageRef = ref(storage, `${folderPath}/${imageName}`);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.error('Error fetching image:', error);
        throw error;
    }
};
