import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAO1iYerqbX0E88e5GmMqYUpOxSZyQFIbY",
    authDomain: "squarecomputers-c8d02.firebaseapp.com",
    databaseURL: "https://squarecomputers-c8d02-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "squarecomputers-c8d02",
    storageBucket: "squarecomputers-c8d02.appspot.com",
    messagingSenderId: "928660043240",
    appId: "1:928660043240:web:7168ad91c8a10534e1e05d",
    measurementId: "G-48B92HCB8C"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

export { database, storage };
